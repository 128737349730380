import { Component, OnInit, Input } from '@angular/core';
import { ChargebackDataService } from 'app/chargeback-data.service';
import { ChargebackDocument } from 'app/chargebackTypes';
import swal from 'sweetalert2';
import { formatDate } from '@angular/common';

declare var $: any;

@Component({
  selector: 'app-chargeback-case-documents',
  templateUrl: './chargeback-case-documents.component.html',
  styleUrls: ['./chargeback-case-documents.component.css']
})
export class ChargebackCaseDocumentsComponent implements OnInit {


  @Input() caseId = '';
  chargebackDocumentList?: ChargebackDocument[];
  docHeader = ['Filename', 'Size', 'Last modified', 'Actions'];

  constructor(private chargebackDataService: ChargebackDataService, ) { }

  ngOnInit(): void {
    if (this.caseId && this.caseId !== '') {
      this.refreshFiles();
    }
  }


  refreshFiles() {
    this.chargebackDataService
      .getUploadedFilesForChargeback(this.caseId)
      .subscribe(
        (response) =>
          (this.chargebackDocumentList = response as ChargebackDocument[])
      );
  }

  deleteFile(fileName: string) {
    swal.fire({
      html: `<p>Are you sure you want to delete <b>${fileName}</b>?</p>
              <p>This cannot be undone.</p>`,
      icon: 'warning',

      showCancelButton: true,
      customClass: {
        confirmButton: 'btn btn-fill btn-success btn-mr-5',
        cancelButton: 'btn btn-fill btn-danger',
      },
      confirmButtonText: 'Ok',
      buttonsStyling: false,

    }).then((result) => {
      if (result.isConfirmed) {
        if (this.caseId) {
          this.chargebackDataService.deleteFileForChargeback(this.caseId, fileName)
            .subscribe(response => {
              this.refreshFiles();
              $.notify(
                {
                  message: 'File has been successfully deleted.',
                },
                {
                  type: 'success',
                  timer: 4000,
                  placement: {
                    from: 'top',
                    align: 'center',
                  },
                }
              );
            });
        }
      }
    });
    /* if (this.caseId) {
        this.chargebackDataService.deleteFileForChargeback(this.caseId, fileName)
          .subscribe(response => this.refreshFiles());
     }*/
  }

  uploadClick(event: any) {

    if (event.files && event.files.length > 0) {
      let formData = new FormData();
      let imageFile = event.files[0];
      /** get the file extension to make sure it is allowed. */
      let fileExt = imageFile.name.split('.').pop();
      /** Prepare possibly two alert messages (in one alert) if the file type is not supported or if it is too large. */
      let alertMessageArray = [];
      /** Is this a supported file extension? */
      if (fileExt && ['tif', 'tiff', 'pdf', 'jpeg', 'jpg', 'TIF', 'TIFF', 'PDF', 'JPEG', 'JPG'].indexOf(fileExt) === -1) {
        alertMessageArray.push('Please select a supported file type (tif, pdf, or jpg).');
      }
      if (imageFile.size > 2100000) {
        alertMessageArray.push('Please select a file that is 2MB or less.');
      }

      if (alertMessageArray.length > 0) {
        let alertMessage = `<p><b>The following problems have been encountered with the selected file:</b></p>`;
        alertMessageArray.forEach(element => {
          alertMessage = `${alertMessage}<li>${element}</li>`
        });
        swal.fire({
          title: '',
          html: alertMessage,

          customClass: {
            confirmButton: 'btn btn-fill btn-success btn-mr-5',

          },
          confirmButtonText: 'Ok',
          buttonsStyling: false,

        }).then((result) => {

        });
      } else {
        formData.append('file', event.files[0] as File, this.getFileName(event.files[0].name));
        this.chargebackDataService.uploadFileForChargeback(this.caseId, formData)
          .subscribe(result => {
            this.refreshFiles();
            $.notify(
              {
                message: 'File has been successfully uploaded.',
              },
              {
                type: 'success',
                timer: 4000,
                placement: {
                  from: 'top',
                  align: 'center',
                },
              }
            );
          });

      }

    }
  }

  getFileName(currentFileName: string) {
    let returnFileName = currentFileName;
    this.chargebackDocumentList.forEach(doc => {
      if (doc.fileName === currentFileName) {
        const splitName = currentFileName.split('.');
        returnFileName = `${splitName.slice(0, -1).join('.')}-${formatDate(Date.now(), 'hhmmss', 'en-us', '-8')}.${splitName[splitName.length - 1]}`;
      }
    })
    return returnFileName;
  }

}
