import { Routes } from '@angular/router';

import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';
import { ChargebackGridComponent } from './chargeback-grid/chargeback-grid.component';
import { ChargebackCaseDetailComponent } from './chargeback-case-detail/chargeback-case-detail.component';
import { LoginComponent } from './pages/login/login.component';

export const AppRoutes: Routes = [{
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
},
{
    path: 'login',
    component: AuthLayoutComponent,
    children: [{
        path: '',
        component: LoginComponent
    }]
},
{
    path: '',
    component: AdminLayoutComponent,
    children: [
    {
        path: 'chargebacks',
        component: ChargebackGridComponent
    },
    {
        path: 'chargebacks/:status',
        component: ChargebackGridComponent
    },
    {
        path: 'caseDetail/:caseNumber',
        component: ChargebackCaseDetailComponent
    },
    {
        path: 'dashboard',
        component: ChargebackGridComponent
    }
    ]
},/*
{
    path: 'tmp',
    component: AuthLayoutComponent,
    children: [{
        path: 'login',
        loadChildren: './pages/pages.module#LoginModule'
    }]
}*/
];
