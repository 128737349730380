import { Input } from '@angular/core';
import { Component, OnInit, ElementRef } from '@angular/core';
import { ChargebackDataService } from '../../chargeback-data.service';
import { Router } from '@angular/router';


declare var $:any;

@Component({
    moduleId:module.id,
    selector: 'login-cmp',
    templateUrl: './login.component.html'
})

export class LoginComponent implements OnInit{
    test : Date = new Date();

    username?: string;
    password?: string;

    checkFullPageBackgroundImage(){
        var $page = $('.full-page');
        var image_src = $page.data('image');

        if(image_src !== undefined){
            var image_container = '<div class="full-page-background" style="background-image: url(' + image_src + ') "/>'
            $page.append(image_container);
        }
    };
    
    onSubmit(){
        this.chargebackDataService.loginWithUsernameAndPassword(this.username, this.password)
            .subscribe((result) => {
                if (result.result) {
                    this.router.navigate(['/chargebacks/Open']);
                } else {
                    $.notify(
                        {
                          message: 'Please enter the correct user name and password.',
                        },
                        {
                          type: 'danger',
                          timer: 4000,
                          placement: {
                            from: 'top',
                            align: 'center',
                          },
                        }
                      );
                }
            });

    }
    constructor(
        private chargebackDataService: ChargebackDataService,
        private router: Router
        ) { }

    ngOnInit(){
        this.checkFullPageBackgroundImage();
        this.username = '';
        this.password = '';
        setTimeout(function(){
            // after 1000 ms we add the class animated to the login/register card
            $('.card').removeClass('card-hidden');
        }, 700)
    }
}
