import { Injectable } from '@angular/core';
import { ChargebackData } from './chargebackTypes';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, pipe, of } from 'rxjs';
import { formatDate } from '@angular/common';
import { catchError, map } from 'rxjs/operators';


const CurrentUserId = '24A66AB5-BEB4-4617-8834-F60BF52C5B4A';
const BASE_URL = 'https://resolve-api.appsos.com/api';

@Injectable({
  providedIn: 'root',
})
export class ChargebackDataService {
  /*private encodedLogin?: string = Buffer.from(
    'demouser' + ':' + 'password1'
  ).toString('base64');
*/

  private encodedLogin = '';

  getHeaders(additionalOption?) {
    const headers = new HttpHeaders()
      .set('Authorization', 'Basic ' + this.encodedLogin)
      .set('CurrentUserId', CurrentUserId)
    if (additionalOption && additionalOption.key !== '') {
      headers.set(additionalOption.key, additionalOption.value);
    }

      return headers;
  }

  loginWithUsernameAndPassword(username: string, password: string) {
    this.encodedLogin = Buffer.from(username + ':' + password).toString(
      'base64'
    );

    return this.http.post<any>(`${BASE_URL}/AuthManagement/Login`, '', {
      headers: {
        CurrentUserId: CurrentUserId,
        Authorization: 'Basic ' + this.encodedLogin,
        'Content-Type': 'application/json',
      },
    }).pipe(
      catchError((err) => {
        return of(err);
      })
    );
  }

  getChargebackData(): Observable<ChargebackData[] | boolean> {
    return this.http
      .get<ChargebackData[]>(`${BASE_URL}/ChargebackRecords?queue=0`, {
        headers: this.getHeaders(),
      })
      .pipe(map((data) => this.sanitizeArray(data) as ChargebackData[]),
      catchError((err) => {
        return of(false);
      }));
  }

  getChargebackDataForCase(caseNumber: string) {
    return this.http
      .get<ChargebackData | boolean>(`${BASE_URL}/ChargebackRecords/${caseNumber}`, {
        headers: this.getHeaders()
      })
      .pipe(map((data) => this.sanitizeArray([data as ChargebackData])),
      catchError((err) => {
        return of(false);
      }));
  }

  updateChargebackRecordWithNote(chargebackId: string, newNote: string) {
    return this.http.post(
      `${BASE_URL}/ChargebackRecords/${chargebackId}/Notes`,
      JSON.stringify({ Text: newNote }),
      {
        headers: {
          CurrentUserId: CurrentUserId,
          Authorization: 'Basic ' + this.encodedLogin,
          'Content-Type': 'application/json',
        },
      }
    );
  }

  getUploadedFilesForChargeback(chargebackId: string) {
    return this.http.get(`${BASE_URL}/ChargebackRecords/${chargebackId}/List`, {
      headers: this.getHeaders(),
    });
  }

  deleteFileForChargeback(chargebackId: string, fileName: string) {
    const options = {
      headers: this.getHeaders({'Content-Type': 'application/json'}),
      body: {
        FileName: fileName,
      }
    }

    return this.http.delete(
      `${BASE_URL}/ChargebackRecords/${chargebackId}/Delete`, options
       );
  }

  uploadFileForChargeback(chargebackId: string, formData: FormData) {
    return this.http.post(
      `${BASE_URL}/ChargebackRecords/${chargebackId}/Upload`,
      formData,
      {
        headers: this.getHeaders({ 'enctype': 'multipart/form-data' })
      }
    );
  }

  getDownloadLinkForChargebackLetter(chargebackId: string) {
    return this.http.get(
      `${BASE_URL}/ChargebackRecords/${chargebackId}/DownloadLetter`,
      {
        headers: this.getHeaders({'responseType': 'text'}),
        responseType: 'text',
      }
    );
  }

  updateChargebackRecordWithStatus(chargebackId: string, newStatus: number) {

    return this.http.post<any>(
      `${BASE_URL}/ChargebackRecords/${chargebackId}/${newStatus}`,
      {},
      { headers: this.getHeaders() }
    );
  }

  constructor(private http: HttpClient) {}

  sanitizeArray(records: ChargebackData[]) {
    const numericalFields = [
      'transactionAmount',
      'sourceAmount',
      'sourceCurrencyCode',
      'originalBillingAmount',
      'caseType',
      'statusId',
    ];

    const recordsToReturn = records;
    records.forEach((record, index) => {
      let tmpRecord = record;
      let property: keyof typeof tmpRecord;
      for (property in tmpRecord) {
        if (tmpRecord[property] === null) {
          if (numericalFields.indexOf(property) > -1) {
            // ie, it is one of the fields that are numbers
            tmpRecord = { ...tmpRecord, [property]: 0 };
          } else {
            // it is a string
            tmpRecord = { ...tmpRecord, [property]: '' };
          }
        } else if (property === 'caseType') {
          tmpRecord[property] = this.convertCaseType(
            tmpRecord[property] as number
          );
        } else if (property === 'receivedDate') {
          tmpRecord[property] = formatDate(
            new Date(tmpRecord.receivedDate),
            'MM/dd/yy',
            'en-US'
          );
        } else if (property === 'respondByDate') {
          tmpRecord[property] = formatDate(
            new Date(tmpRecord.respondByDate as string),
            'MM/dd/yy',
            'en-US'
          );
        } else if (property === 'transactionDate') {
          tmpRecord[property] = formatDate(
            new Date(tmpRecord.transactionDate),
            'MM/dd/yy',
            'en-US'
          );
        } else if (property === 'transactionAmount') {
          tmpRecord['transactionAmount'] = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
          }).format(tmpRecord.transactionAmount as number);
        }
      }
      recordsToReturn[index] = tmpRecord;
    });

    return recordsToReturn;
  }
  convertCaseType = (number: number) => {
    switch (number) {
      case 0:
        return 'Retrieval';
      case 1:
        return 'Chargeback';
      case 2:
        return '2nd Chargeback';
      case 3:
        return 'Reversal';
      case 4:
        return '2nd Reversal';
      case 9:
        return 'Pre Arbitration';
      default:
        return ' ';
    }
  };
}
