import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; // this is needed!
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { APP_BASE_HREF } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { AppComponent }   from './app.component';
import { AgGridModule } from 'ag-grid-angular';
import { SidebarModule } from './sidebar/sidebar.module';
import { FixedPluginModule } from './shared/fixedplugin/fixedplugin.module';
import { FooterModule } from './shared/footer/footer.module';
import { NavbarModule} from './shared/navbar/navbar.module';
import { PagesnavbarModule} from './shared/pagesnavbar/pagesnavbar.module';
import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';
import { AppRoutes } from './app.routing';
// import { ChargebacktableComponent } from './chargebacktable/chargebacktable.component';
import { ChargebackGridComponent } from './chargeback-grid/chargeback-grid.component';
import { ChargebackCaseDetailComponent } from './chargeback-case-detail/chargeback-case-detail.component';
import { ChargebackCaseDocumentsComponent } from './chargeback-case-documents/chargeback-case-documents.component';
import { LoginComponent } from './pages/login/login.component';
  
@NgModule({
    imports:      [
        BrowserAnimationsModule, 
        FormsModule,
        RouterModule.forRoot(AppRoutes,{
          useHash: true
        }),
        HttpClientModule,
        SidebarModule,
        NavbarModule,
        FooterModule,
        FixedPluginModule,
        PagesnavbarModule,
        AgGridModule.withComponents([])
    ],
    declarations: [
        AppComponent,
        AdminLayoutComponent,
        AuthLayoutComponent,
        // ChargebacktableComponent,
        ChargebackGridComponent,
        ChargebackCaseDetailComponent,
        ChargebackCaseDocumentsComponent,
        LoginComponent
    ],
    bootstrap:    [ AppComponent ]
})

export class AppModule { }
