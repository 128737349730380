import { Component, OnInit, NgModule } from '@angular/core';
import { ChargebackData } from '../chargebackTypes';
import { ChargebackDataService } from '../chargeback-data.service';
import { ColDef, RowClickedEvent } from 'ag-grid-community';

import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { compareDesc } from 'date-fns';
import { differenceInCalendarDays } from 'date-fns';



declare var $: any;


@Component({
  selector: 'app-chargeback-grid',
  templateUrl: './chargeback-grid.component.html',
  styleUrls: ['./chargeback-grid.component.css']
})
export class ChargebackGridComponent implements OnInit {

  status = 'Open';

  chargebackData?: ChargebackData[];
  chargebackDataForFiltering?: ChargebackData[];
  columnDefs?: ColDef[];

  statusOptions = ['Open', 'Submitted', 'Closed', 'Reports'];
  gridOptions = {
    context: {
      parentComponent: this,
    },
  };
  gridSearchString?: string;

  gridApi?: any;

  toFilterTime?: Date;
  fromFilterTime?: Date;

  constructor(
    private chargebackDataService: ChargebackDataService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.route.params.subscribe(params => {
      this.status = params['status'];
      this.refreshData();
      this.applyNewColumns();
    });
  }

  onRowSelected(event: RowClickedEvent) {
    this.router.navigate([`/caseDetail/${event.data.publicId}`]);
  }


  ngOnInit(): void {

    this.status = this.route.snapshot.paramMap.get('status');
  
    $('.datepicker').datetimepicker({
      format: 'MM/DD/YYYY',    // use this format if you want the 12hours timpiecker with AM/PM toggle
      icons: {
        time: 'fa fa-clock-o',
        date: 'fa fa-calendar',
        up: 'fa fa-chevron-up',
        down: 'fa fa-chevron-down',
        previous: 'fa fa-chevron-left',
        next: 'fa fa-chevron-right',
        today: 'fa fa-screenshot',
        clear: 'fa fa-trash',
        close: 'fa fa-remove'
      }
    });
  }

  clearElement(elementToClear: HTMLInputElement) {
    elementToClear.value = '';
    if (elementToClear.id === 'toDatePicker') {
      this.toFilterTime = null;
    } else if (elementToClear.id === 'fromDatePicker') {
      this.fromFilterTime = null;
    }
    this.filterDataByTime();
  }

  selectTime(datePickerElement: HTMLInputElement) {
    if (datePickerElement.id === 'toDatePicker') {
      this.toFilterTime = new Date(datePickerElement.value);
    } else if (datePickerElement.id === 'fromDatePicker') {
      this.fromFilterTime = new Date(datePickerElement.value);
    }
    this.filterDataByTime();
  }
  filterDataByTime() {
    let endRangeDate = new Date();
    if (!this.fromFilterTime) {// if no start date in range, don't filter.
      this.chargebackData = this.chargebackDataForFiltering;
      return;
    }
    // if there is a end of range, use that. (needed in case there was no end range, in which case use today's date).
    if (this.toFilterTime) {
      endRangeDate = this.toFilterTime;
    }
    console.log(this.chargebackData.length);
    this.chargebackData = this.chargebackDataForFiltering.filter(element => {
      return (compareDesc(this.fromFilterTime, new Date(element.receivedDate)) === 1
        && compareDesc(new Date(element.receivedDate), endRangeDate) === 1);
    })

  }

  refreshData() {
    this.chargebackDataService.getChargebackData().subscribe((data) => {
      if (!data) {
        this.chargebackData = [];
        this.chargebackDataForFiltering = this.chargebackData;
        $.notify(
          {
            message: 'Unable to get Chargebacks. Please try again later.',
          },
          {
            type: 'danger',
            timer: 4000,
            placement: {
              from: 'top',
              align: 'center',
            },
          }
        );
      } else {
        this.chargebackData = data as ChargebackData[];
        this.chargebackDataForFiltering = this.chargebackData;
      }

    });
  }
  applyNewColumns() {
    if (this.status === 'Open') {
      this.columnDefs = this.openColumnDefs;
    } else if (this.status === 'Submitted' || this.status === 'Closed') {
      this.columnDefs = this.submittedClosedColumnDefs;
    } else {
      this.columnDefs = this.reportsColumnDefs;
    }
  }
  exportCSV() {
    this.gridApi.exportDataAsCsv({allColumns: true});
  }
  isExternalFilterPresent() {
    return true;
  }
  onFilterTextBoxChanged(event) {
    this.gridApi.setQuickFilter(event);
  }
  doesExternalFilterPass(node: any) {
    const currentContext = this as any;
    const statusCheck = currentContext.context.parentComponent.status;
    const row = node.data as unknown as ChargebackData;
    switch (statusCheck) {
      case 'Open':
        return row.statusId === 1 || row.statusId === 2;
      case 'Submitted':
        return row.statusId === 5;
      case 'Closed':
        return row.statusId === 3 || row.statusId === 4;
      case 'Reports':
        return true;
      default:
        return false;
    }
  }
  onGridReady(params) {
    params.api.sizeColumnsToFit();
    this.gridApi = params.api;
  }
  /**
 * I can't call any other functions bc of the way ag-grid handles being a child of a class. So...I have to do it like this
 * until I learn another way to do it.
 * See https://angular.io/guide/inputs-outputs for a possible solution?
 * @param params
 * @returns
 */
  statusValueFormatter(params: any) {
    const number = params.value as number;
    let primaryReturn = '';
    let secondaryReturn = '';

    if (number === 1 || number === 2) {
      primaryReturn = 'Open';
    } else if (number === 3 || number === 4) {
      primaryReturn = 'Closed';
    } else if (number === 5) {
      primaryReturn = 'Submitted';
    } else if (number === 6) {
      primaryReturn = 'Represented';
    } else {
      primaryReturn = '';
    }
    switch (number) {
      case 1:
        secondaryReturn = 'Open';
        break;
      case 2:
        secondaryReturn = 'Reopened';
        break;
      case 3:
        secondaryReturn = 'Expired';
        break;
      case 4:
        secondaryReturn = 'Completed';
        break;
      case 5:
        secondaryReturn = 'Pending Completion';
        break;
      case 6:
        secondaryReturn = 'Represented';
        break;
      default:
        secondaryReturn = '';
    }
    return primaryReturn + ' - ' + secondaryReturn;
  }

  formatRespondByDate(respondBy: any) {
    let tmpDate = new Date(respondBy.value as string);
    return differenceInCalendarDays(tmpDate, new Date()) + '';
  }

  getLastFour(params: any) {
    let tmp = params.value as string;
    let four = tmp.split('.');
    return four[four.length - 1];
  }

  submittedClosedColumnDefs = [
    { field: 'dbaName', headerName: 'DBA', flex: 2 },
    { field: 'mid', headerName: 'MID', flex: 2 },
    { field: 'receivedDate', flex: 1.5 },
    { field: 'caseType', flex: 1.5 },
    { field: 'appsosCaseNumber', headerName: 'Case Number', flex: 2 },
    { field: 'transactionAmount', headerName: 'Amount', flex: 1, maxWidth: 100 },
    {
      field: 'cardholderAccountNumber',
      headerName: 'Last Four',
      valueFormatter: this.getLastFour,
      flex: 1,
      maxWidth: 100,
      getQuickFilterText: params => {
        return params.value.name;
      }
    },
    {
      field: 'statusId',
      headerName: 'Status',
      flex: 3,
      valueFormatter: this.statusValueFormatter,
    },
  ];

  reportsColumnDefs = [
    { field: 'dbaName', headerName: 'DBA', flex: 1 },
    { field: 'mid', headerName: 'MID', flex: 1.5 },
    { field: 'receivedDate', flex: 1 },
    { field: 'caseType', flex: 1 },
    { field: 'appsosCaseNumber', headerName: 'Case Number', flex: 1 },
    { field: 'transactionAmount', headerName: 'Amount', flex: 1 },
    {
      field: 'cardholderAccountNumber',
      headerName: 'Card Number',
      flex: 1,

    },
    {
      field: 'statusId',
      headerName: 'Status',
      valueFormatter: this.statusValueFormatter,
      flex: 2
    },
    {
      field: 'debitAdviceVerbiageShort',
      headerName: 'Reason',
      hide: true
    }
  ];


  openColumnDefs = [
    { field: 'dbaName', headerName: 'DBA', flex: 1 },
    { field: 'mid', headerName: 'MID', flex: 1 },
    {
      field: 'receivedDate',
      flex: .5,
    },
    { field: 'caseType', flex: .75 },
    { field: 'appsosCaseNumber', headerName: 'Case Number', flex: .75 },
    { field: 'transactionAmount', headerName: 'Amount', flex: .5, maxWidth: 100 },
    {
      field: 'cardholderAccountNumber',
      headerName: 'Last Four',
      valueFormatter: this.getLastFour,
      flex: .5,
      maxWidth: 100,
      getQuickFilterText: params => {
        return params.value.name;
      }
    },
    {
      field: 'respondByDate',
      headerName: 'Days to Respond',
      valueFormatter: this.formatRespondByDate,
      flex: .5,
      maxWidth: 100,
      getQuickFilterText: params => {
        return params.value.name;
      }
    },
  ];

}
