import { Component, OnInit } from "@angular/core";
import { ChargebackData, DisplayDataType } from "app/chargebackTypes";
import { ChargebackDataService } from "app/chargeback-data.service";
import { ActivatedRoute } from "@angular/router";
import { Location } from "@angular/common";
import { Router } from "@angular/router";
import { DomSanitizer } from "@angular/platform-browser";
import swal from 'sweetalert2';

declare var $: any;

const NOTES = "notes";
const UPLOAD = "upload";

@Component({
  selector: "app-chargeback-case-detail",
  templateUrl: "./chargeback-case-detail.component.html",
  styleUrls: ["./chargeback-case-detail.component.css"],
})
export class ChargebackCaseDetailComponent implements OnInit {
  chargebackCase?: ChargebackData;
  displayData?: DisplayDataType[]; // special type to make displaying the data easier. See method setDisplayData() below.
  additionalNote?: string;
  chargebackDownloadURL?: string;
  selectedOption = '';

  dataLookupFailure: boolean;

  constructor(
    private chargebackDataService: ChargebackDataService,
    private location: Location,
    private route: ActivatedRoute,
    private router: Router,
    private domSanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    const caseNumber = this.route.snapshot.paramMap.get('caseNumber');
    if (caseNumber && caseNumber !== "") {
      this.chargebackDataService
        .getChargebackDataForCase(caseNumber)
        .subscribe((caseData) => {
          if (!caseData) {
            this.dataLookupFailure = true;
          } else {
          this.chargebackCase = caseData[0];
          if (
            this.chargebackCase.additionalNote &&
            this.chargebackCase.additionalNote !== ''
          ) {
            this.additionalNote = this.chargebackCase.additionalNote;
          }
          this.displayData = this.getDisplayData(caseData[0]);
          if (this.chargebackCase.publicId) {
            this.chargebackDataService
              .getDownloadLinkForChargebackLetter(this.chargebackCase.publicId)
              .subscribe((result) => {
                this.chargebackDownloadURL = result;
              });
          }
        }
        });
    }
  }
  saveNote(showResponseMessage: boolean) {
    if (this.chargebackCase && this.additionalNote) {
      this.chargebackDataService
        .updateChargebackRecordWithNote(
          this.chargebackCase?.publicId as string,
          this.additionalNote
        )
        .subscribe((response) => {
          // WHAT DOES AN UNSUCCESSFUL SAVE RESPONSE LOOK LIKE?
          if (showResponseMessage) {
            this.showSuccessfulSave(NOTES);
          }
        });
    }
  }
 
  showSuccessfulSave(type: string) {
    let message = '';
    if (type === NOTES){
      message = "Notes successfully saved."
    }
    else if (type === UPLOAD){
      message = "Document Successfully uploaded."
    }
    $.notify(
      {
        message: message,
      },
      {
        type: "success",
        timer: 4000,
        placement: {
          from: "top",
          align: "center",
        },
      }
    );
  }

  backClick() {
    this.location.back();
  }
  submitClick() {
    if (this.selectedOption === '') {
      $.notify(
        {
          message: 'Please select either "Accept" or "Dispute."',
        },
        {
          type: "danger",
          timer: 4000,
          placement: {
            from: "top",
            align: "center",
          },
        }
      );
    } else if (this.selectedOption === 'accept') {
      swal.fire({
        text: 'Are you sure you want to accept this chargeback?',
        icon: 'warning',
  
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-fill btn-success btn-mr-5',
          cancelButton: "btn btn-fill btn-danger",
        },
        confirmButtonText: 'Ok',
        buttonsStyling: false,
  
      }).then((result) => {
        console.log(result);
        if (result.isConfirmed && this.chargebackCase?.publicId) {
          //at some point ill maybe put in a check to see if the note needs to be saved.
          this.saveNote(false);
          this.chargebackDataService
            .updateChargebackRecordWithStatus(this.chargebackCase?.publicId, 5)
            .subscribe((result) => this.location.back());
        }
      });
    } else if (this.selectedOption === 'dispute') {
      swal.fire({
        text: 'Are you sure you want to dispute this chargeback?',
        icon: 'warning',
  
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-fill btn-success btn-mr-5',
          cancelButton: "btn btn-fill btn-danger",
        },
        confirmButtonText: 'Ok',
        buttonsStyling: false,
  
      }).then((result) => {
        console.log(result);
        if (result.isConfirmed && this.chargebackCase?.publicId) {
          //at some point ill maybe put in a check to see if the note needs to be saved.
          
          this.saveNote(false);
          this.chargebackDataService
            .updateChargebackRecordWithStatus(this.chargebackCase?.publicId, 5)
            .subscribe((result) => this.location.back());
        }
      });

    }
  }

  reopenClick() {
    swal.fire({
      text: 'Are you sure you want to reopen this case?',
      icon: 'warning',

      showCancelButton: true,
      customClass: {
        confirmButton: 'btn btn-fill btn-success btn-mr-5',
        cancelButton: "btn btn-fill btn-danger",
      },
      confirmButtonText: 'Ok',
      buttonsStyling: false,

    }).then((result) => {
      console.log(result);
      if (result.isConfirmed && this.chargebackCase?.publicId) {
        this.saveNote(false);
        this.chargebackDataService
          .updateChargebackRecordWithStatus(this.chargebackCase?.publicId, 2)
          .subscribe((result) => this.location.back());
      }
    });
 

  }

  /*
swal.fire({
      html: `<p>Are you sure you want to delete <b>${fileName}</b>?</p>
              <p>This cannot be undone.</p>`,
      icon: 'warning',

      showCancelButton: true,
      customClass: {
        confirmButton: 'btn btn-fill btn-success btn-mr-5',
        cancelButton: "btn btn-fill btn-danger",
      },
      confirmButtonText: 'Ok',
      buttonsStyling: false,

    }).then((result) => {
      if (result.isConfirmed) {
        if (this.caseId) {
          this.chargebackDataService.deleteFileForChargeback(this.caseId, fileName)
            .subscribe(response => {
              this.refreshFiles();
              $.notify(
                {
                  message: 'File has been successfully deleted.',
                },
                {
                  type: "success",
                  timer: 4000,
                  placement: {
                    from: "top",
                    align: "center",
                  },
                }
              );
            });
        }
      }
    });

  */

  getDisplayData(chargeback: ChargebackData) {
    return [
      { title: "Case Number", data: chargeback.appsosCaseNumber },
      { title: "Case Type", data: `${chargeback.caseType}` },
      {
        title: "Status",
        data: this.getPrimaryStatusWithId(chargeback.statusId),
      },
      {
        title: "Transaction Date",
        data: chargeback.transactionDate,
      },
      {
        title: "Date Received",
        data: chargeback.receivedDate,
      },
      {
        title: "Cardholder Number",
        data: chargeback.cardholderAccountNumber,
      },
      {
        title: "Amount",
        data: chargeback.transactionAmount as string, //I think this will be ok?
      },
      { title: "Reference Number", data: chargeback.referenceNumber },
      { title: "Reason Code", data: chargeback.chargebackReasonCode },
    ];
  }

  getPrimaryStatusWithId(number: number) {
    if (number === 1 || number === 2) {
      return "Open";
    } else if (number === 3 || number === 4) {
      return "Closed";
    } else if (number === 5) {
      return "Submitted";
    } else if (number === 6) {
      return "Represented";
    } else {
      return "";
    }
  }
}
